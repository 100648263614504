import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import GlobalHeader from 'components/GlobalHeader';
import MobileBottomBar from 'components/common/MobileBottomBar';
import MainTemplate from 'components/templates/MainTemplate';
import AppPortal from 'components/AppPortal';
import SplashScreen from 'components/common/SplashScreen';
import SnackBar from 'components/SnackBar';
import LoginDialog from 'components/common/LoginDialog';
import DashboardSidebar from 'components/DashboardSidebar';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import usePostActionStore, { UploadStatus } from 'store/post_action';
import GlobalUploadingProgress from 'components/common/GlobalUploadingProgress';
import {
  isProduction,
  parseJSON,
  TRACKING_EVENTS_KEY_NAME,
  useMediaQuery,
} from 'common/utils';
import PostActionsList from 'components/common/PostActionsList';
import Script from 'next/script';
import styles from './AppLayout.module.scss';
import GTMScript from 'components/common/GTMScript';
import axios from 'axios';
import { useQuery } from 'react-query';
import { GetCurrentUserProfileResult } from 'common/interfaces/api';
import PostPrimeTracking, {
  sendUserEvents,
  TrackingEvent,
} from 'common/utils/pp_tracking';

import { rentracksLPTrigger } from 'common/utils/trackingTag';
import { getAllAlertSettings } from 'service/chart';
import { isGoldMember, isIzanaviMember } from 'common/utils/membership';
import { getCoinDetailsInfo, getMissions } from 'service/user';
import classNames from 'classnames';
import CoursePageSection from 'components/CoursePageSection';
import { addPageViewEvent } from 'common/utils/user';
import { getAllAlarms } from 'service/anime';
import AllModalsSection from 'components/modules/AllModalsSection';

const MobilePromotionBanner = dynamic(
  () => import('components/common/MobilePromotionBanner'),
  {
    ssr: false,
  }
);

const AnimeAlarmsSection = dynamic(
  () => import('components/AnimeAlarmsSection'),
  {
    ssr: false,
  }
);

const TippingDialog = dynamic(() => import('components/common/TippingDialog'), {
  ssr: false,
});

const PaymentDialog = dynamic(() => import('components/common/PaymentDialog'), {
  ssr: false,
});

const MemberShipPayDialog = dynamic(
  () => import('components/common/MemberShipPayDialog'),
  {
    ssr: false,
  }
);

export type Props = {
  children: ReactElement;
};

export default function AppLayout({ children }: Props): JSX.Element {
  const router = useRouter();
  const { pathname, asPath } = router;
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const setRedirectUrl = useGlobalStore((state) => state.setRedirectUrl);
  const [loadedLive2DScriptCore, setLoadedLive2DScriptCore] = useState(false);
  const [loadedLive2DScript, setLoadedLive2DScript] = useState(false);
  const setAllSymbolAlertSettings = useGlobalStore(
    (state) => state.setAllSymbolAlertSettings
  );
  const currentUser = useStore((state) => state.currentUser);
  const shouldRefetchProfile = useStore((state) => state.shouldRefetchProfile);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const showPaymentDialogInput = useGlobalStore(
    (state) => state.showPaymentDialogInput
  );
  const showTippingDialogInput = useGlobalStore(
    (state) => state.showTippingDialogInput
  );
  const showMembershipPayDialogInput = useGlobalStore(
    (state) => state.showMembershipPayDialogInput
  );
  const shouldRefetchAlertSettings = useGlobalStore(
    (state) => state.shouldRefetchAlertSettings
  );
  const setAllMissions = useStore((state) => state.setAllMissions);
  const setAllAlarms = useStore((state) => state.setAllAlarms);
  const setUserCoinDetails = useGlobalStore(
    (state) => state.setUserCoinDetails
  );
  const shouldRefetchMissions = useStore(
    (state) => state.shouldRefetchMissions
  );
  const shouldRefetchCoinDetails = useStore(
    (state) => state.shouldRefetchCoinDetails
  );
  const shouldRefetchAlarms = useStore((state) => state.shouldRefetchAlarms);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isHome = pathname === '/';
  const isNotification = pathname === '/notification';
  const isDashboard = pathname.startsWith('/dashboard');
  // Course Creator Side
  const isCourseCreate = pathname.startsWith(`/dashboard/my_courses/new`);
  const isCourseEdit = pathname.startsWith(`/dashboard/my_courses/[courseId]`);
  // Course User Side
  const isCoursesPage = pathname.startsWith(`/courses`);
  // isProfile includes post pages
  const isProfile = pathname.startsWith('/[username]');
  const isPostDetail = pathname.startsWith('/[username]/posts/[postId]');
  const isSearch = pathname === '/search';
  const isAccount = pathname.startsWith('/account');
  const isTopic = pathname.startsWith('/topics');
  const isPostTopic = pathname.startsWith('/post_topics');
  const isRedirectNew = pathname === '/redirect/new';
  const isTrendingTopicsPage = pathname === '/trending_topics';
  const isCreatorRanking = pathname === '/creators/rankings';
  const isChart = pathname === '/chart';
  const isPrimePosts = pathname === '/prime_posts';
  const isVerifyEmail = pathname.startsWith('/mails/verify');
  const isCmpPage = pathname.startsWith('/cmp/');
  const isMembershipPage = pathname === '/p/membership';
  const isAffiliateMissionPage =
    pathname === '/p/ig_mission' ||
    pathname === '/p/sbi_neo_mission' ||
    pathname === '/p/invast_mission';
  const isLiveStream = pathname.startsWith('/livestream');
  const isLP = pathname.startsWith('/lp');
  const isHelpPage =
    pathname.startsWith('/help') || pathname.startsWith('/privacy_policy');
  const postActions = usePostActionStore((state) => state.postActions);
  // redirect to home page if user is not logged in
  useEffect(() => {
    if (
      isAuthInit &&
      !currentAuth &&
      (isNotification ||
        isDashboard ||
        isSearch ||
        isAccount ||
        isChart ||
        isRedirectNew ||
        isLiveStream ||
        isPrimePosts)
    ) {
      setRedirectUrl(asPath);
      void router.push('/');
    }
  }, [
    currentAuth,
    isAuthInit,
    router,
    setRedirectUrl,
    asPath,
    isNotification,
    isDashboard,
    isProfile,
    isPrimePosts,
    isSearch,
    isAccount,
    isRedirectNew,
    isChart,
    isLiveStream,
  ]);

  const { refetch: refetchProfile } = useQuery(
    ['getCurrentUserProfile', currentAuth?.uid],
    async () => {
      if (!currentAuth) return Promise.reject();
      return (await axios.get('/profiles/me'))
        .data as GetCurrentUserProfileResult;
    },
    {
      onSuccess: (data) => {
        const user = data?.data;
        if (user && currentAuth) {
          setCurrentUser(user);
        }
      },
    }
  );

  const { refetch: refetchCoinDetails } = useQuery(
    ['getCoinDetail', currentAuth?.uid],
    async () => {
      if (!currentAuth) return Promise.reject();
      return await getCoinDetailsInfo();
    },
    {
      onSuccess: (data) => {
        setUserCoinDetails(data?.data);
      },
      refetchOnWindowFocus: false,
      enabled: !!currentAuth,
    }
  );

  const { refetch: refetchMissionsData } = useQuery(
    ['getAllMissions', currentAuth?.uid],
    async () => {
      if (!currentAuth) return Promise.reject();
      return getMissions();
    },
    {
      enabled: !!currentAuth,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAllMissions(data?.data?.missions || []);
      },
    }
  );

  const { refetch: refetchAlarms } = useQuery(
    ['getAllAlarms'],
    async () => {
      if (!currentAuth) return Promise.reject();
      return await getAllAlarms();
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!currentAuth,
      onSuccess: (data) => {
        setAllAlarms(data?.data?.alarms || []);
      },
      refetchInterval: 1000 * 60 * 30,
    }
  );

  useEffect(() => {
    if (shouldRefetchMissions) {
      void refetchMissionsData();
    }
  }, [shouldRefetchMissions, refetchMissionsData]);

  useEffect(() => {
    if (shouldRefetchAlarms) {
      void refetchAlarms();
    }
  }, [shouldRefetchAlarms, refetchAlarms]);

  useEffect(() => {
    if (shouldRefetchProfile) {
      void refetchProfile();
      void refetchCoinDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchProfile, refetchProfile]);

  useEffect(() => {
    if (shouldRefetchCoinDetails) {
      void refetchCoinDetails();
    }
  }, [shouldRefetchCoinDetails, refetchCoinDetails]);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (postActions.some((action) => action.status !== UploadStatus.DONE))
        return 'Do you really want to close?';
    };
  }, [postActions]);

  useEffect(() => {
    try {
      const newTrackingObj = new PostPrimeTracking();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      newTrackingObj.init();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (window as any).postPrimeTracking = newTrackingObj;
      // eslint-disable-next-line no-empty
    } catch (error) {}
    const intervalTracking = setInterval(() => {
      try {
        if (typeof window === 'undefined') return;
        const logEventData =
          localStorage.getItem(TRACKING_EVENTS_KEY_NAME) || '[]';
        const currentEvents = (parseJSON(logEventData) ||
          []) as TrackingEvent[];
        if (currentEvents.length > 0) {
          sendUserEvents(currentEvents);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }, 20000);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (window as any).postPrimeTracking = null;
      clearInterval(intervalTracking);
    };
  }, []);

  const { refetch: refetchAllAlert } = useQuery(
    [
      'getAllSymbolAlertData',
      isGoldMember(currentUser) || isIzanaviMember(currentUser),
    ],
    async () => {
      if (!isGoldMember(currentUser) && !isIzanaviMember(currentUser))
        return Promise.reject();
      return getAllAlertSettings();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAllSymbolAlertSettings(data?.data || []);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetchAlertSettings) {
      void refetchAllAlert();
    }
  }, [shouldRefetchAlertSettings, refetchAllAlert]);

  useEffect(() => {
    if (isHome || isLP) {
      rentracksLPTrigger();
    }
  }, [isHome, isLP]);

  useEffect(() => {
    try {
      let currentPath = pathname;
      const params = asPath.split('?')[1];
      if (params) {
        const tabParam = params
          .split('&')
          .find((param) => param.startsWith('tab='));
        if (tabParam) {
          currentPath = `${pathname}?${tabParam}`;
        }
      }
      addPageViewEvent(currentPath);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  // redirect to the page user tried to access before signing in (if there is one)
  const redirectUrl = useGlobalStore((state) => state.redirectUrl);
  useEffect(() => {
    if (currentAuth) {
      if (redirectUrl && redirectUrl !== router.asPath) {
        void router.push(redirectUrl);
        setRedirectUrl(null);
      }
      void currentAuth.getIdToken();
    }
  }, [currentAuth, redirectUrl, router, setRedirectUrl]);

  let content: JSX.Element;
  if (isPostDetail && !isMobileScreen) {
    content = (
      <>
        <MobilePromotionBanner />
        <AppPortal />
        <MainTemplate hasRightSidebar={false} mainSectionFitContent={true}>
          {children}
        </MainTemplate>
      </>
    );
  } else if (
    (currentAuth && (isNotification || isSearch || isChart || isPrimePosts)) ||
    isProfile ||
    isHome ||
    isCmpPage ||
    isTrendingTopicsPage ||
    isTopic ||
    isCreatorRanking ||
    isPostTopic ||
    isAffiliateMissionPage
  ) {
    content = (
      <>
        <MobilePromotionBanner />
        {isMobileScreen && <MobileBottomBar />}
        <AppPortal />
        <MainTemplate>{children}</MainTemplate>
      </>
    );
  } else if (isDashboard) {
    if (isCourseCreate || isCourseEdit) {
      content = <div className={styles.container}>{children}</div>;
    } else {
      content = (
        <>
          <GlobalHeader />
          <div className={styles.container}>
            <div className={styles.dashboardPageWrapper}>
              <div className={styles.sidebar}>
                <DashboardSidebar />
              </div>
              <div className={styles.content}>{currentAuth && children}</div>
            </div>
          </div>
        </>
      );
    }
  } else if (isAccount || isRedirectNew) {
    content = (
      <>
        <MobilePromotionBanner />
        <GlobalHeader />
        {isMobileScreen && <MobileBottomBar />}
        <div className={styles.container}>{children}</div>
      </>
    );
  } else if (isLiveStream) {
    content = <div className={styles.container}>{children}</div>;
  } else if (isMembershipPage) {
    content = (
      <>
        {isMobileScreen && <MobileBottomBar />}
        <MainTemplate hasRightSidebar={false} mainSectionFitContent={true}>
          {children}
        </MainTemplate>
      </>
    );
  } else if (isCoursesPage) {
    content = (
      <>
        {isMobileScreen && <MobileBottomBar />}
        <MainTemplate hasRightSidebar={false} mainSectionFitContent={true}>
          <CoursePageSection>{children}</CoursePageSection>
        </MainTemplate>
      </>
    );
  } else if (isHelpPage) {
    content = <div className={styles.container}>{children}</div>;
  } else {
    content = children;
  }

  let wrappedContent;
  if (!isAuthInit) {
    wrappedContent = (
      <>
        <SplashScreen />
        {content}
      </>
    );
  } else {
    if (!currentAuth || isVerifyEmail) {
      wrappedContent = content;
    } else {
      wrappedContent = (
        <>
          {showPaymentDialogInput && <PaymentDialog />}
          {showTippingDialogInput && <TippingDialog />}
          {showMembershipPayDialogInput?.open && <MemberShipPayDialog />}
          {content}
        </>
      );
    }
  }

  const isSupportAnimeCharacter = !isLP && !isHelpPage;
  return (
    <div
      className={classNames('yp-app', { [styles.isLp]: isLP || isHelpPage })}
    >
      {!currentAuth && <LoginDialog />}
      {wrappedContent}
      {currentAuth && !isMobileScreen && (
        <div className={styles.uploadingProgress}>
          <GlobalUploadingProgress />
          <PostActionsList />
        </div>
      )}
      {currentAuth && currentUser && <AllModalsSection />}
      {isSupportAnimeCharacter &&
        currentAuth &&
        loadedLive2DScript &&
        loadedLive2DScriptCore && <AnimeAlarmsSection />}
      <SnackBar />
      <GTMScript />
      {currentAuth && (
        <>
          <Script
            src="/js/live2dcubismcore.min.js"
            strategy="afterInteractive"
            onLoad={() => {
              setLoadedLive2DScriptCore(true);
            }}
          />
          <Script
            src="/js/live2d_v1.min.js"
            strategy="afterInteractive"
            onLoad={() => {
              setLoadedLive2DScript(true);
            }}
          />
        </>
      )}
      {isProduction() && (
        <>
          {/* PostPrime */}
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=AW-10835403462"
            strategy="afterInteractive"
          />
          <Script
            id="a8-js"
            src="//statics.a8.net/a8sales/a8sales.js"
            data-a8stoplog="1"
            strategy="afterInteractive"
          />
          <Script id="pp-google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-10835403462');
            `}
          </Script>
        </>
      )}
    </div>
  );
}
