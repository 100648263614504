import { User } from 'common/interfaces/api';
import { NextAlarm } from 'common/utils/anime';
import { AlarmType } from 'service/anime';
import { Mission } from 'service/user';
import create, { SetState } from 'zustand';

type TimelineStore = {
  currentUser: User;
  shouldRefetchNotifications: number;
  shouldRefetchPost: number;
  shouldRefetchTippedListOfPost: number;
  shouldRefetchMissions: number;
  shouldRefetchAlarms: number;
  shouldRefetchAlarmSymbols: number;
  shouldRefetchPostComments: number;
  shouldRefetchRepliesCommentId: number;
  shouldRefetchPosts: number;
  shouldRefetchProfile: number;
  shouldRefetchCoinDetails: number;
  shouldRefetchCurrentProfile: number;
  shouldRefetchSymbolData: number;
  activeAudioPlayer: { id: string; playing: boolean } | null;
  activeVideoPlayer: { id: string; muted: boolean } | null;
  allMissions: Mission[];
  setAllMissions: (missions: Mission[]) => void;
  allAlarms: AlarmType[];
  setAllAlarms: (alarms: AlarmType[]) => void;
  nextTestAlarm: NextAlarm;
  setNextTestAlarm: (nextAlarm: NextAlarm) => void;
  setCurrentUser: (user: User) => void;
  refetchPost: () => void;
  refetchTippedListOfPost: () => void;
  refetchNotifications: () => void;
  refetchPostComments: (commentId?: number) => void;
  refetchPosts: () => void;
  refetchMissions: () => void;
  refetchAlarms: () => void;
  refetchAlarmSymbols: () => void;
  refetchProfile: () => void;
  refetchCoinDetails: () => void;
  refetchCurrentProfile: () => void;
  refetchSymbolData: () => void;
  setActiveAudioPlayer: (id: string, playing: boolean) => void;
  setActiveVideoPlayer: (id: string, muted: boolean) => void;
};

const useStore = create<TimelineStore>((set: SetState<TimelineStore>) => ({
  // states
  currentUser: null,
  // the number doesn't matter, what matters is this number changes whenever posts should be refetched
  shouldRefetchPost: 0,
  shouldRefetchNotifications: 0,
  shouldRefetchTippedListOfPost: 0,
  shouldRefetchPostComments: 0,
  shouldRefetchRepliesCommentId: -1,
  shouldRefetchPosts: 0,
  shouldRefetchProfile: 0,
  shouldRefetchAlarmSymbols: 0,
  shouldRefetchCurrentProfile: 0,
  shouldRefetchMissions: 0,
  shouldRefetchAlarms: 0,
  shouldRefetchSymbolData: 0,
  activeAudioPlayer: null,
  activeVideoPlayer: null,
  allMissions: [],
  nextTestAlarm: null,
  setAllMissions: (missions: Mission[]) => set({ allMissions: missions }),
  allAlarms: [],
  setAllAlarms: (alarms: AlarmType[]) => set({ allAlarms: alarms }),
  // actions
  setCurrentUser: (user: User) => set({ currentUser: user }),
  refetchPost: () =>
    set((state) => ({
      shouldRefetchPost: state.shouldRefetchPost + 1,
    })),
  refetchTippedListOfPost: () =>
    set((state) => ({
      shouldRefetchTippedListOfPost: state.shouldRefetchTippedListOfPost + 1,
    })),
  refetchPostComments: (commentId?: number) =>
    set((state) => ({
      shouldRefetchPostComments: state.shouldRefetchPostComments + 1,
      shouldRefetchRepliesCommentId: commentId ? commentId : -1,
    })),
  refetchNotifications: () =>
    set((state) => ({
      shouldRefetchNotifications: state.shouldRefetchNotifications + 1,
    })),
  refetchPosts: () =>
    set((state) => ({
      shouldRefetchPosts: state.shouldRefetchPosts + 1,
    })),
  refetchProfile: () =>
    set((state) => ({
      shouldRefetchProfile: state.shouldRefetchProfile + 1,
    })),
  refetchCurrentProfile: () =>
    set((state) => ({
      shouldRefetchCurrentProfile: state.shouldRefetchCurrentProfile + 1,
    })),
  setActiveAudioPlayer: (id: string, playing: boolean) => {
    set({ activeAudioPlayer: { id, playing } });
  },
  setActiveVideoPlayer: (id: string, muted: boolean) => {
    if (!id) {
      set({ activeVideoPlayer: null });
    }
    set({ activeVideoPlayer: { id, muted } });
  },
  refetchMissions: () =>
    set((state) => ({
      shouldRefetchMissions: state.shouldRefetchMissions + 1,
    })),
  refetchAlarms: () =>
    set((state) => ({
      shouldRefetchAlarms: state.shouldRefetchAlarms + 1,
    })),
  refetchAlarmSymbols: () =>
    set((state) => ({
      shouldRefetchAlarmSymbols: state.shouldRefetchAlarmSymbols + 1,
    })),
  refetchSymbolData: () =>
    set((state) => ({
      shouldRefetchSymbolData: state.shouldRefetchSymbolData + 1,
    })),
  setNextTestAlarm: (nextAlarm: NextAlarm) => set({ nextTestAlarm: nextAlarm }),
  shouldRefetchCoinDetails: 0,
  refetchCoinDetails: () =>
    set((state) => ({
      shouldRefetchCoinDetails: state.shouldRefetchCoinDetails + 1,
    })),
}));

export default useStore;
